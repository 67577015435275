import {
  createStyles,
  Title,
  Text,
  Button,
  Center,
  Space,
  Anchor,
  Image,
  Box,
  Stack,
  BackgroundImage,
  Modal,
  UnstyledButton,
  Menu,
  Group,
  Divider,
  Burger,
  Transition,
  Paper,
  Drawer,
  ActionIcon,
} from '@mantine/core';
import AppLogo from '../components/AppLogo';

import sayHelpLogoWhite from '../assets/sayhelp-logo-white.svg';
import { ReactComponent as PlayStoreBadge } from '../assets/playStoreBadge.svg';
import { ReactComponent as AppStoreBadge } from '../assets/appStoreBadge.svg';

import { ReactComponent as TwitterIcon } from '../assets/twitterIcon.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagramIcon.svg';
import { ReactComponent as TiktokIcon } from '../assets/tiktokIcon.svg';
import { ReactComponent as YoutubeIcon } from '../assets/youtubeIcon.svg';

// Photos
import sayhelpAppPreview from '../assets/sayhelpAppPreview.png';
import landingPhotoOne from '../assets/landing-photo-1.png';
import landingPhotoTwo from '../assets/landing-photo-2.png';
import landingPhotoThree from '../assets/landing-photo-3.png';

import appPreview1 from '../assets/app-preview@2x.jpg';
import appPreview2 from '../assets/app-preview-2@2x.jpg';
import advantagesBg from '../assets/advantages-bg.jpg';

import phonePreview1 from '../assets/phone-preview-1.jpg';
import phonePreview2 from '../assets/phone-preview-2.jpg';

import sayhelpAppPhone from '../assets/sayhelp-app-phone-crop.png';

// import cityLandscapeBottom from '../assets/city-landscape-bottom.png';
import cityLandscapeBottomWebp from '../assets/city-landscape-bottom.webp';

import { RiInstagramFill } from 'react-icons/ri';

import { themeColors } from '../config/themeSettings';
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
  useMatch,
  Outlet,
} from 'react-router-dom';
import { useState } from 'react';

import AboutContent from './AboutContent';
import LandingContent from './LandingContent';
import PricingContent from './PricingContent';
import VideoContent from './VideoContent';
import BlogContent from './BlogContent';
import FAQContent from './FAQContent';
import { useDisclosure } from '@mantine/hooks';
import AppDivider from '../components/AppDivider';
import SayHelpLogo from './components/SayHelpLogo';
import { IconX } from '@tabler/icons';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import CookieConsent from './components/CookieConsent';
import CookieButton from './components/CookieButton';
import OperatingStatusContent from './OperatingStatusContent';
import JobsContent from './JobsContent';
import FoodDelivery from './articles/FoodDelivery';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import ChatbotComponent from '../components/chatbotHelper';

const toggleDarkMode = () => {
  document.documentElement.classList.toggle('c_darkmode');
};

function HomePage({ title }) {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const [opened, { toggle, close }] = useDisclosure(false);

  let PageContent = <LandingContent />;

  const [appStoreModal, setAppStoreModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { pageContent } = useParams();

  const menuPage = pageContent ? pageContent : 'home';

  if (menuPage === 'about') PageContent = <AboutContent />;
  if (menuPage === 'pricing') PageContent = <PricingContent />;
  if (menuPage === 'operating') PageContent = <OperatingStatusContent />;
  if (menuPage === 'video') PageContent = <VideoContent />;
  if (menuPage === 'jobs') PageContent = <JobsContent />;
  if (menuPage === 'blog') PageContent = <BlogContent />;
  if (menuPage === 'faq') PageContent = <FAQContent />;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const menuLinkClassName =
    menuIsOpen ||
    menuPage === 'faq' ||
    menuPage === 'blog' ||
    menuPage === 'video'
      ? classes.menuLinkActive
      : classes.menuLink;

  const scaleY = {
    in: { opacity: 1, transform: 'scaleY(1)' },
    out: { opacity: 0, transform: 'scaleY(0)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  return (
    <div className={classes.root}>
      <ChatbotComponent />
      <Box className={classes.container} pt={20}>
        <Modal
          opened={appStoreModal}
          radius={15}
          styles={{ modal: classes.modalSoon }}
          closeOnClickOutside={true}
          closeOnEscape={true}
          onClose={() => setAppStoreModal(false)}
          centered>
          <Stack align="center">
            <Title order={4}>App Coming soon...</Title>
            <div>
              <Image src={sayhelpAppPhone} style={{ width: 250 }} />
            </div>
          </Stack>
        </Modal>
        <div className={classes.flex}>
          <Box className={classes.grid} px={20}>
            <Burger
              opened={opened}
              onClick={toggle}
              className={classes.burger}
            />
            <Anchor component={Link} to="/home">
              <SayHelpLogo />
            </Anchor>

            {/* <Space w="100%" /> */}
            <section className={classes.menuFlex}>
              <Box className={classes.menuBurger}>
                <Drawer
                  withCloseButton={false}
                  withinPortal={true}
                  position="top"
                  overlayOpacity={0.55}
                  size={550}
                  transitionDuration={450}
                  transitionTimingFunction="cubic-bezier(0.17,0.84,0.44,1)"
                  transition="slide-down"
                  // transition={scaleY}
                  opened={opened}
                  onClose={toggle}
                  styles={{
                    drawer: classes.menuDrawer,
                  }}>
                  <ActionIcon
                    variant="transparent"
                    size={40}
                    onClick={toggle}
                    className={classes.burgerDrawerPosition}>
                    <IconX color="white" size={30} stroke={1.2} />
                    {/* <Burger color="white" opened={opened} /> */}
                  </ActionIcon>

                  <Stack align="center" className={classes.dropdown} mt={70}>
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        toggle();
                        navigation('/home');
                      }}>
                      Home
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/about');
                        toggle();
                      }}>
                      About
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/pricing');
                        toggle();
                      }}>
                      Pricing
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/operating');
                        toggle();
                      }}>
                      Say Help News
                    </UnstyledButton>
                    {/* <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/chat');
                        toggle();
                      }}>
                      Chat
                    </UnstyledButton> */}
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/video');
                        toggle();
                      }}>
                      How It Works
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/faq');
                        toggle();
                      }}>
                      FAQ
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/blog');
                        toggle();
                      }}>
                      Blog
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        window.open('https://community.sayhelp.com/', '_blank');
                        toggle();
                      }}>
                      Community Forum
                    </UnstyledButton>
                    <AppDivider />
                    <UnstyledButton
                      className={classes.menuBurgerItems}
                      onClick={() => {
                        navigation('/home/jobs');
                        toggle();
                      }}>
                      Jobs
                    </UnstyledButton>
                  </Stack>
                </Drawer>
              </Box>
              <div spacing={5} className={classes.menu}>
                <Menu width={200} shadow="md">
                  <Menu.Target>
                    <UnstyledButton
                      className={
                        menuPage === 'home'
                          ? classes.menuLinkActive
                          : classes.menuLink
                      }
                      onClick={() => navigation('/home')}>
                      Home
                    </UnstyledButton>
                  </Menu.Target>

                  <Menu.Target>
                    <UnstyledButton
                      onClick={() => navigation('/home/about')}
                      className={
                        menuPage === 'about'
                          ? classes.menuLinkActive
                          : classes.menuLink
                      }>
                      <Text>About</Text>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Target>
                    <UnstyledButton
                      className={
                        menuPage === 'pricing'
                          ? classes.menuLinkActive
                          : classes.menuLink
                      }
                      onClick={() => navigation('/home/pricing')}>
                      Pricing
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Target>
                    <UnstyledButton
                      className={
                        menuPage === 'operating'
                          ? classes.menuLinkActive
                          : classes.menuLink
                      }
                      onClick={() => navigation('/home/operating')}>
                      Say Help News
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu
                    //trigger="hover"
                    openDelay={100}
                    closeDelay={400}
                    position="bottom"
                    offset={15}
                    onOpen={toggleMenu}
                    radius={6}
                    onClose={toggleMenu}
                    styles={{
                      dropdown: classes.menuDropdown,
                      item: classes.menuDropdownItems,
                    }}>
                    <Menu.Target>
                      <UnstyledButton
                        className={menuLinkClassName}
                        style={{ display: 'flex', alignItems: 'center' }}>
                        Resources{' '}
                        {menuIsOpen ? (
                          <IconChevronUp className={classes.chevronDown} />
                        ) : (
                          <IconChevronDown className={classes.chevronDown} />
                        )}
                        {/* <IconChevronDown className={classes.chevronDown} /> */}
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        className={
                          menuPage === 'video'
                            ? classes.dpMenuLinkActive
                            : classes.dpMenuLink
                        }
                        onClick={() => navigation('/home/video')}>
                        How it Works{' '}
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'gray',
                            marginTop: 5,
                            whiteSpace: 'normal',
                          }}>
                          Video tutorials and quick guides to navigate the app.
                        </div>
                      </Menu.Item>
                      <AppDivider />
                      <Menu.Item
                        className={
                          menuPage === 'faq'
                            ? classes.dpMenuLinkActive
                            : classes.dpMenuLink
                        }
                        onClick={() => navigation('/home/faq')}>
                        FAQ{' '}
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'gray',
                            marginTop: 5,
                            whiteSpace: 'normal',
                          }}>
                          Answers to common questions – browse our FAQ section
                          for quick solutions and helpful insights.
                        </div>
                      </Menu.Item>
                      <AppDivider />
                      <Menu.Item
                        className={
                          menuPage === 'blog'
                            ? classes.dpMenuLinkenuLinkActive
                            : classes.dpMenuLink
                        }
                        onClick={() => navigation('/home/blog')}>
                        Blog{' '}
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'gray',
                            marginTop: 5,
                            whiteSpace: 'normal',
                          }}>
                          Stay updated with our latest articles, tips, and news
                          – your source for valuable insights and information.
                        </div>
                      </Menu.Item>
                      <AppDivider />
                      <Menu.Item
                        className={
                          menuPage === 'forum'
                            ? classes.dpMenuLinkenuLinkActive
                            : classes.dpMenuLink
                        }
                        component="a"
                        href="https://community.sayhelp.com/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Community Forum
                        <div
                          style={{
                            fontSize: '12px',
                            color: 'gray',
                            marginTop: 5,
                            whiteSpace: 'normal',
                          }}>
                          Explore our community forum to connect with others,
                          share experiences, and more!
                        </div>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  <Menu.Target>
                    <UnstyledButton
                      className={
                        menuPage === 'jobs'
                          ? classes.menuLinkActive
                          : classes.menuLink
                      }
                      onClick={() => navigation('/home/jobs')}>
                      Jobs
                    </UnstyledButton>
                  </Menu.Target>
                </Menu>
              </div>
            </section>
            <div className={classes.badgesTop}>
              <a href="https://play.google.com/store/apps/details?id=com.sayhelp.help">
                <PlayStoreBadge width={122} height={35} />
              </a>
              <a href="https://apps.apple.com/app/say-help/id1418901033">
                <AppStoreBadge width={122} height={36} />
              </a>
            </div>
          </Box>
          {/* <section className={classes.badges}>
             <UnstyledButton onClick={() => setAppStoreModal(true)}>
              <PlayStoreBadge width={122} height={35} />
            </UnstyledButton>
            <UnstyledButton onClick={() => setAppStoreModal(true)}>
              <AppStoreBadge width={122} height={36} />
            </UnstyledButton> 
          </section> */}

          {PageContent}
        </div>
      </Box>
      <Box>
        <Box className={classes.containerDark} pt={50}>
          <div className={classes.flexBottom}>
            <Stack spacing={15} className={classes.flexBottomLogo}>
              <SayHelpLogo />

              <div className={classes.termsBottom}>
                {/* <Anchor
                href="https://sayhelp.com/tos"
                target="_blank"
                className={classes.anchor}>
                Terms & Conditions
              </Anchor> */}

                <Anchor component={Link} to="/tos" className={classes.anchor}>
                  Terms & Conditions
                </Anchor>
                <Anchor
                  component={Link}
                  to="/privacy"
                  className={classes.anchor}>
                  Privacy Policy
                </Anchor>
                <CookieButton
                  onClick={() => {
                    window.CookieConsentApi.showSettings(0);
                  }}
                />
              </div>
              <div className={classes.termsSocial}>
                <a
                  href="https://www.facebook.com/sayhelpapp"
                  rel="noopener noreferrer"
                  target="_blank">
                  <FacebookIcon width={40} />
                </a>
                <a
                  href="https://twitter.com/SayHelp_"
                  rel="noopener noreferrer"
                  target="_blank">
                  <TwitterIcon width={40} />
                </a>
                <a
                  href="https://www.instagram.com/itssayhelpofficial/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <InstagramIcon width={40} />
                </a>
                <a
                  href="https://www.tiktok.com/@itssayhelpofficial?_t=8iA9Raa5Wz1&_r=1/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <TiktokIcon width={40} />
                </a>

                <a
                  href="https://www.youtube.com/@itssayhelpofficial/featured/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <YoutubeIcon width={40} />
                </a>
              </div>
            </Stack>
            <Stack spacing={15}>
              {/* <Text className={classes.cta}>Get the free app on your phone</Text> */}
              <Text className={classes.ctaTitle}>
                Get the App on your phone
              </Text>

              <section className={classes.badges}>
                <Stack spacing={5} align="center">
                  <a href="https://play.google.com/store/apps/details?id=com.sayhelp.help">
                    <PlayStoreBadge width={122} height={35} />
                  </a>
                </Stack>
                <Stack spacing={5} align="center">
                  <a href="https://apps.apple.com/app/say-help/id1418901033">
                    <AppStoreBadge width={122} height={36} />
                  </a>
                </Stack>
              </section>
              <Text
                className={classes.ctaTitle}
                style={{ position: 'relative', zIndex: 2 }}>
                USA: <a href="tel:+18335689123">1-833-568-9123</a>
              </Text>
              <Text
                className={classes.ctaTitle}
                style={{ position: 'relative', zIndex: 2 }}>
                Mexico: <a href="tel:+523385262321">(+52) 33-8526-2321</a>
              </Text>
              <Text
                className={classes.ctaTitle}
                style={{ position: 'relative', zIndex: 2 }}>
                Philippines: <a href="tel:+1800113220487">1-800-1-322-0487</a>
              </Text>
              <Text
                className={classes.ctaTitle}
                style={{ position: 'relative', zIndex: 2 }}>
                South Africa: <a href="tel:+270430011141">(+27) 043-001-1141</a>
              </Text>
              <Text
                className={classes.ctaTitle}
                style={{ position: 'relative', zIndex: 2 }}>
                <a href="mailto:support@sayhelp.com">support@sayhelp.com</a>.
              </Text>
            </Stack>
          </div>
        </Box>
        <Box className={classes.containerDarkBottom}>
          <div className={classes.flex}>
            <div className={classes.containerGreyBottom}>
              <section className={classes.darksection}>
                <Center>
                  <Image
                    src={cityLandscapeBottomWebp}
                    className={classes.bottomImagePosition}
                  />
                </Center>
              </section>
            </div>
          </div>
        </Box>
      </Box>
      <CookieConsent />
    </div>
  );
}

export default HomePage;

const useStyles = createStyles(theme => ({
  root: {
    fontFamily: 'Poppins',
    backgroundColor: themeColors.white,
    height: '100%',
    width: '100%',
    overflowX: 'hidden',

    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  container: {
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingTop: 20,
  },

  containerDark: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    position: 'relative',
  },

  chevronDown: {
    marginLeft: 5,
  },

  menuDropdown: {
    backgroundColor: '#141414',
    maxWidth: 300,
    border: 'none',
    boxShadow: 'none',
  },

  menuDropdownItems: {
    backgroundColor: '#141414 !important',
    color: 'white',
    fontSize: 14,
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    // General Gap between header buttons title and elements
    gap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto',
    justifyContent: 'space-between',

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
    },
    '@media (max-width: 1430px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      gap: 20,
    },

    '@media (max-width: 768px)': {
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
      gap: 0,
    },
    '@media (max-width: 325px)': {
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: 'auto',
      justifyContent: 'center',
      alignContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
      gap: 0,
    },
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // alignItems: 'center',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 50,
    },

    '@media (max-width: 425px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // gap: 20,
    },
  },

  menuFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 10,
    borderRadius: 6,
    backgroundColor: themeColors.menu,
    height: 40,

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },

  menuLink: {
    color: '#C1C1C1',
    // fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    borderRight: '1px solid #D8D8D8',
    whiteSpace: 'nowrap',
    textDecoration: 'none',

    ':last-child': {
      borderRight: 'none',
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    // outlineOffset: '-7px',

    ':hover': {
      color: themeColors.white,
      transition: '0.5s color',
    },
    transition: '0.5s color',
  },

  menuLinkActive: {
    color: themeColors.white,
    // color: 'dodgerblue',
    // fontWeight: 'normal',
    // fontWeight: 'bold',
    paddingLeft: 34,
    paddingRight: 34,

    WebkitTapHighlightColor: 'rgba(0,0,0,0)',

    borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },

  dpMenuLink: {
    color: '#C1C1C1',
    // fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 24,

    //borderRight: '1px solid #D8D8D8',
    whiteSpace: 'nowrap',
    textDecoration: 'none',

    ':last-child': {
      borderRight: 'none',
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    // outlineOffset: '-7px',

    ':hover': {
      color: themeColors.white,
      transition: '0.5s color',
    },
    transition: '0.5s color',
  },

  dpMenuLinkActive: {
    color: themeColors.white,
    // color: 'dodgerblue',
    // fontWeight: 'normal',
    // fontWeight: 'bold',
    paddingLeft: 10,
    paddingRight: 24,

    WebkitTapHighlightColor: 'rgba(0,0,0,0)',

    //borderRight: '1px solid #D8D8D8',

    ':last-child': {
      borderRight: 'none',
    },

    '@media (max-width: 425px)': {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
    },

    webkitTransition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    transition: 'opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },

  badgesTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    gpa: 20,

    '@media (max-width: 325px)': {
      marginTop: 10,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
    },
    '@media (min-width: 326px) and (max-width: 425px)': {
      marginTop: 10,
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      // justifyItems: 'center',
      // alignSelf: 'center',
      gap: 20,
    },

    '@media (min-width: 426px) and (max-width: 768px)': {
      marginTop: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
    },

    '@media (min-width: 1024px)': {
      marginTop: 0,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 20,
    },
  },

  anchor: {
    color: themeColors.anchor,
    fontSize: 14,
  },
  badges: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 20,
  },

  apppreviewPosition: {
    // position: 'absolute',
    // top: 0,
  },

  header: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 900px)',
    gridTemplateRows: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 20,
    // gridColumn:

    // maxWidth: 1000,
  },

  title: {
    fontFamily: 'Poppins',
    // fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  titleWhite: {
    fontFamily: 'Poppins',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // maxWidth: 900,
    textAlign: 'center',
    color: themeColors.white,

    '@media (min-width: 768px) and (max-width: 1023px) ': {
      fontSize: 20,
    },
    '@media (max-width: 767px)': {
      fontSize: 27,
    },
  },
  subtitle: {
    fontFamily: 'Poppins',
    // fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.anchor,
  },

  redTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    letterSpacing: 4,
    textAlign: 'center',
    color: '#ec1d23',
    textTransform: 'uppercase',
  },

  ctaTitle: {
    fontFamily: 'Poppins',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    letterSpacing: 1,
    textAlign: 'center',
    // color: '#ec1d23',
    textTransform: 'uppercase',
  },

  redbutton: {
    // updated fonts
    fontSize: 14,
    fontWeight: 'bold',
    color: themeColors.white,
    backgroundColor: '#EC1D23',
    width: 250,
  },

  rightIcon: {
    marginLeft: 0,
  },

  containerGrey: {
    backgroundColor: themeColors.backgound,
    // height: 900,
    // minHeight: 400,
  },

  darksectionMiddle: {
    paddingLeft: 20,
    paddingRight: 20,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,

    '@media (max-width: 767px) ': {
      paddingBottom: 60,
    },

    '@media (min-width: 768px) and (max-width: 1023px)': {
      paddingBottom: 80,
    },
    '@media (min-width: 1024px) ': {
      paddingBottom: 80,
    },
  },

  darksection: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  positionUp: {
    width: '100%',
    position: 'absolute',
    bottom: '-20vw',
    // top: -330,
    left: 0,
    right: 0,
    // margin: '0 auto',

    '@media (min-width: 1024px)': {
      bottom: '-210px',
    },
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   top: '-38vw',
    // },
    // '@media (max-width: 767px)': {
    //   top: '-38vw',
    // },
    // '@media (min-width: 1024px)': {
    //   top: '-310px',
    //   // top: '-330px',
    //   // left: '20%',
    //   // right: '20%',
    // },
    // position: 'absolute',
    // top: -100,
    // '@media (min-width: 768px) and (max-width: 1023px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (max-width: 767px)': {
    //   transform: 'translate(0,-33vw)',
    // },
    // '@media (min-width: 1024px)': {
    //   transform: 'translate(0,-337px)',
    // },
  },

  appPreview: {
    maxWidth: 854,
    width: '80%',
    height: 'auto',
    margin: '0 auto',

    '@media (min-width: 768px) and (max-width: 1023px)': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
    '@media (max-width: 767px) ': {
      height: 'auto',
      margin: '0 auto',
      width: '80%',
    },
  },

  space: {
    // height: 1215,
    // minHeight: 400,
    paddingBottom: '45vw',

    '@media (min-width: 1024px)': {
      paddingBottom: '450px',
    },
  },

  photosFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 20,

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      gap: 50,
      justifyContent: 'space-around',
      // maxWidth: 900,
    },
  },

  contentflex: {
    // paddingTop: 30,
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) minmax(min-content, 1fr)',
    gridTemplateRows: 'auto',

    justifyContent: 'space-evenly',

    gap: 20,

    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  },

  contentflexReverse: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',

    gap: 20,

    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },

  titleRedLine: {
    // fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.88,
    letterSpacing: 'normal',
    color: themeColors.text,

    borderLeft: '6px solid #EC1D23',
    paddingLeft: 20,
  },

  description: {
    // fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 2.15,
    letterSpacing: 'normal',
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerDarkImage: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 20,
    paddingBottom: 20,

    '@media (min-width: 1024px)': {
      // paddingTop: 100,
      // paddingBottom: 100,
    },
  },

  titleOutline: {
    fontFamily: 'Poppins',
    WebkitTextStroke: '1px #ec1c24',
    fontSize: 26,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: themeColors.text,
  },

  advantagesFlex: {
    // paddingTop: 50,

    // paddingLeft: 30,
    // paddingRight: 30,

    '@media (max-width: 768px)': {
      paddingTop: 20,
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 20,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },

  descriptionWhite: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',

    color: themeColors.white,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      fontSize: 16,
      lineHeight: 2.13,
    },
    '@media (min-width: 1023px)': {
      fontSize: 20,
    },
  },

  advantagesDescriptionFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 350,
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  advFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,

    '@media (min-width: 768px)': {
      paddingLeft: 30,
      paddingRight: 30,
    },
    '@media (min-width: 1024px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },

  phonePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,

    '@media (min-width: 768px)': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  },

  phonePreviewImages: {
    maxWidth: 150,
    width: '80%',
    // height: 'auto',
    // margin: '0 auto',
  },

  phonePreviewDescriptionTablet: {
    paddingTop: 20,

    '@media (min-width: 1024px)': {
      paddingTop: 50,
      paddingBottom: 100,
      maxWidth: 450,
    },
  },

  phonePreviewDescription: {
    paddingTop: 20,

    '@media (min-width: 768px)': {
      paddingTop: 130,
      paddingBottom: 130,
      maxWidth: 350,
    },
  },

  appPreviewImages: {
    maxWidth: 550,
    width: '100%',
  },

  flexReverse: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  flexBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    // General Gap between header buttons title and elements
    gap: 20,

    paddingLeft: 50,
    paddingRight: 50,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },

  flexBottomLogo: {
    alignItems: 'center',
    '@media (min-width: 768px)': {
      alignItems: 'flex-start',
    },
  },

  termsBottom: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 50,
    },
  },

  termsSocial: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 30,

    // '@media (min-width: 768px)': {
    //   flexDirection: 'row',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   gap: 50,
    // },
  },

  cta: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: themeColors.red,

    '@media (min-width: 768px)': {
      fontSize: 20,
    },
  },

  bottomImagePosition: {
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      // width: 1100,
      // height: 300,
      minWidth: 1020,
      objectPosition: 'center',
      imagePosition: 'center',
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      minWidth: 1020,

      objectPosition: 'center',
      objectGit: 'cover',
      imagePosition: 'center',
    },

    '@media (min-width: 1024px)': {
      maxWidth: 1600,
      objectPosition: 'center',
      imagePosition: 'center',
    },
  },

  containerGreyBottom: {
    backgroundColor: themeColors.backgound,
    height: 110,
    position: 'relative',
    '@media (min-width: 768px)': {
      height: 110,
    },
    overflowX: 'clip',
    // overflowY: 'initial',
  },

  containerDarkBottom: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 200,
    position: 'relative',
    '@media (min-width: 768px)': {
      paddingTop: 160,
    },
  },
  redTitleBox: {
    paddingTop: '25vw',
    '@media (min-width: 1024px)': {
      paddingTop: 250,
    },
  },

  modalSoon: {
    paddingTop: '10 !important',
    paddingBottom: '0 !important',
    // padding: '0 !important',
  },

  dropdown: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // width: '100%',
    // height: '30%',
    zIndex: 11,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    // backgroundColor: '#1E1E1E',
    color: 'white',

    // [theme.fn.largerThan('sm')]: {
    //   display: 'none',
    // },
  },
  burger: {
    // position: 'absolute',
    // top: 40,
    // left: 30,
    transform: 'translateY(-25px)',
    '@media (min-width: 769px)': {
      display: 'none',
    },
  },

  menuBurger: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  menuDrawer: {
    backgroundColor: '#141414',
  },

  menuBurgerItems: {
    backgroundColor: '#141414',
    color: 'white',
    fontSize: 18,
  },

  burgerDrawerPosition: {
    position: 'absolute',
    top: 35,
    left: 15,
  },
}));
