import React, { useEffect } from 'react';

import chatSayHelpAvatar from '../assets/chatSayHelpAvatar.png';
import chatUserAvatar from '../assets/chatUserAvatar.png';
import chatRobot from '../assets/chatRobot.svg';

function ChatbotComponent() {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'module';
    script.src =
      'https://cdn.jsdelivr.net/gh/reillywynn/FlowiseChatEmbed@latest/dist/web.js';
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      window.Chatbot.init({
        chatflowid: 'ce8ace51-ca9a-4829-9e32-368d3cc97249',
        apiHost: 'https://flowiseai-railway-production-ba4f.up.railway.app',
        chatflowConfig: {
          // topK: 2
        },
        theme: {
          button: {
            backgroundColor: '#DA3831',
            right: 20,
            bottom: 20,
            size: 'small',
            iconColor: 'white',
            customIconSrc: chatRobot,
          },
          chatWindow: {
            welcomeMessage:
              'Hello! Welcome to SayHelp, What can I help you with today? ',
            backgroundColor: '#ffffff',
            height: 700,
            width: 400,
            fontSize: 16,
            poweredByTextColor: '#303235',
            botMessage: {
              backgroundColor: '#f7f8ff',
              textColor: '#303235',
              showAvatar: true,
              avatarSrc: chatSayHelpAvatar,
            },
            userMessage: {
              backgroundColor: '#DA3831',
              textColor: '#ffffff',
              showAvatar: true,
              avatarSrc: chatUserAvatar,
            },
            textInput: {
              placeholder: 'Type your question',
              backgroundColor: '#ffffff',
              textColor: '#303235',
              sendButtonColor: '#3B81F6',
            },
          },
        },
      });
    };

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  if (
    window.location.pathname.indexOf('tos') ||
    window.location.pathname.indexOf('privacy')
  )
    return null;

  return <div id="chatbot-container"></div>;
}

export default ChatbotComponent;
