import React, { Suspense } from 'react';
import { TrafficProvider } from './components/TrafficContext';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ChatbotComponent from './components/chatbotHelper';

import theme from './config/theme';
import AppContainer from './components/AppContainer';

import ErrorPage from './pages/ErrorPage';

import AlertClosedPage from './pages/AlertClosedPage';

import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPlainPage from './pages/TermsPlainPage';
import AppBadgesPage from './pages/AppBadgesPage';
import { Toaster } from 'react-hot-toast';
import { NotificationsProvider } from '@mantine/notifications';

import HomePage from './pages/HomePage';
import AuthPage from './pages/AuthPage';
import DisclaimerText from './pages/DisclaimerText';
import DisclaimerPage from './pages/DisclaimerPage';

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <NotificationsProvider limit={1}>
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 10000,

            style: {
              width: '60%',
              textAlign: 'center',
              boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.07)',
              borderRadius: '50px',
              background: 'rgba(0,0,0, 0.7)',
              color: '#fff',
              fontSize: 11,
            },
          }}
        />
        <TrafficProvider>
          <BrowserRouter>
            <AppContainer>
              <Routes>
                <Route path="/:sessionId/*" element={<AuthPage />} />
                <Route path="/alert/:sessionId/*" element={<AuthPage />} />
                <Route path="/closed" element={<AlertClosedPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/home/:pageContent/*" element={<HomePage />} />
                <Route path="/tos" element={<TermsPage />} />
                <Route path="/tos/:language/*" element={<TermsPage />} />
                <Route path="/tos/plain" element={<TermsPlainPage />} />
                <Route
                  path="/tos/plain/:language/*"
                  element={<TermsPlainPage />}
                />
                <Route path="/disclaimer" element={<DisclaimerPage />} />
                <Route
                  path="/disclaimer/:language/*"
                  element={<DisclaimerPage />}
                />
                <Route path="/app" element={<AppBadgesPage />} />
                <Route path="/app:appQuery" element={<AppBadgesPage />} />
                <Route path="/app/*" element={<AppBadgesPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/privacy/:language/*" element={<PrivacyPage />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </AppContainer>
          </BrowserRouter>
        </TrafficProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
