import React, { useState, useMemo } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import settings from '../config/settings';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/core';
import {
  IconMapPin,
  IconPhone,
  IconClock,
  IconWorld,
  IconAdjustments,
} from '@tabler/icons';
import { Text, Switch, ActionIcon, Menu } from '@mantine/core';
import Dist from '../pages/assets/office-building.png';
import Corporate from '../pages/assets/corporate.png';
import University from '../pages/assets/university.png';
import Restaurant from '../pages/assets/food.png';

const googleMapsApiKey = settings.googleMapsApiKey;

const locations = {
  sims: {
    lat: 20.710934708362377,
    lng: -103.34975154231192,
  },
  swartz: {
    lat: -33.03290433823765,
    lng: 27.865891586427608,
  },
  asset: {
    lat: 29.883748934681226,
    lng: -95.61728513146801,
  },
  pinay: {
    lat: 38.6552260834634,
    lng: -77.30976975943767,
  },
  dantes: {
    lat: 42.70942713565755,
    lng: -73.66182616822398,
  },
  uog: {
    lat: 20.677164400341365,
    lng: -103.35903968916084,
  },
  ecdoe: {
    lat: -32.919082763572206,
    lng: 27.414431906831563,
  },
};

const infoBox = [
  {
    name: 'SIMS',
    address1: 'Juan Garabito 1189 La Guadalupana,',
    address2: '4420 Guadalajara, Jal., Mexico',
    website: 'https://sayhelp.com/home',
    phone: '(+52) 33-8526-2321',
    hours: '9am - 5pm',
    position: locations.sims,
    icon: Dist,
    type: 'distributor',
  },
  {
    name: 'Swartz Industries',
    address1: '20 Ripley Rd, Greenfields',
    address2: 'East London 5201, South Africa',
    website:
      'https://e76g8fgasj.preview-postedstuff.com/V2-7RYb-ahru-ZjtCH-mADs/',
    phone: '(+27) 043-001-1141',
    hours: '9am - 5pm',
    position: locations.swartz,
    icon: Dist,
    type: 'distributor',
  },
  {
    name: 'Asset Cellutions',
    address1: '13750 Farm to Market Rd 529',
    address2: 'Building 2, Houston, TX 77041',
    website: 'https://assetcellutions.com/',
    phone: '(713) 955-2163',
    hours: '9am - 5pm',
    position: locations.asset,
    icon: Corporate,
    type: 'corporate',
  },
  {
    name: 'Pinay Kitchen',
    address1: '13542 Minnieville Rd,',
    address2: 'Woodbridge, VA 22192',
    website: 'https://pinaykitchen.net/',
    phone: '(703) 986-0114',
    hours: '11am - 8pm',
    position: locations.pinay,
    icon: Restaurant,
    type: 'partner',
  },
  {
    name: 'Dantes Pizzeria',
    address1: '311 Spring Ave,',
    address2: 'Troy, NY 12180',
    website: 'https://pinaykitchen.net/',
    phone: '(518) 272-4533',
    hours: '11:30am - 8pm',
    position: locations.dantes,
    icon: Restaurant,
    type: 'partner',
  },
  {
    name: 'University of Guadalajara',
    address1: 'Av Juárez 976, Col Americana,',
    address2: 'Americana, 44100 Guadalajara, Jal., Mexico',
    website: 'http://www.udg.mx/',
    phone: '(+52) 33-3134-2222',
    hours: '9am - 5pm',
    position: locations.uog,
    icon: University,
    type: 'partner',
  },
  {
    name: 'Eastern Cape Dept. of Ed.',
    address1: 'Steve Vukile Tshwete Education Complex,',
    address2: 'Zone 6, Zwelitsha, 5608, South Africa',
    website: 'https://eceducation.gov.za/',
    phone: '(+27) 40-608-4298',
    hours: '9am - 5pm',
    position: locations.ecdoe,
    icon: University,
    type: 'partner',
  },
];

function DistributorLocations() {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 1022px)');

  const center = useMemo(() => {
    return {
      lat: 5.2583,
      lng: isMobile ? -40 : -59.8174,
    };
  }, [isMobile]);

  const mapOptions = {
    gestureHandling: 'cooperative',
    streetViewControl: false,
    // disableDefaultUI: true,
    styles: [
      //Disables the equator and international date line
      {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'off' }],
      },
    ],
    minZoom: isMobile ? 1 : 2,
  };
  const containerStyle = {
    width: '100%',
    height: isMobile ? '350px' : '500px',
    flex: 1,
  };
  const [infoWindowOpen, setInfoWindowOpen] = useState(null);

  const toggleInfoWindow = markerId => {
    setInfoWindowOpen(infoWindowOpen === markerId ? null : markerId);
  };
  const [menuOpened, setMenuOpened] = useState(false);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  //toggle for showing corporate, distributors, and partners
  const [showCorporate, setShowCorporate] = useState(true);
  const [showDistributors, setShowDistributors] = useState(true);
  const [showPartners, setShowPartners] = useState(true);

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={isMobile ? 1.5 : 3}
        options={mapOptions}>
        {infoBox
          .filter(
            markerInfo =>
              (showCorporate && markerInfo.type === 'corporate') ||
              (showDistributors && markerInfo.type === 'distributor') ||
              (showPartners && markerInfo.type === 'partner')
          )
          .map((markerInfo, index) => (
            <Marker
              key={index}
              position={markerInfo.position}
              onClick={() => toggleInfoWindow(index)}
              options={{
                icon: markerInfo.icon, // Use custom icon based on location name
              }}>
              {infoWindowOpen === index && (
                <InfoWindow onCloseClick={() => toggleInfoWindow(index)}>
                  <div className={classes.customInfoWindow}>
                    <div className={classes.line}>
                      <IconMapPin className={classes.mapIcon} />
                      <div>
                        <strong className={classes.title}>
                          {markerInfo.name}
                        </strong>
                        <Text className={classes.text}>
                          {markerInfo.address1}
                        </Text>
                        <Text>{markerInfo.address2}</Text>
                      </div>
                    </div>
                    <div className={classes.line}>
                      <IconWorld
                        size={24}
                        stroke={1.5}
                        className={classes.globeIcon}
                      />
                      <a
                        href={markerInfo.website}
                        target="_blank"
                        rel="noopener noreferrer">
                        {markerInfo.name} Website
                      </a>
                    </div>
                    <div className={classes.line}>
                      <IconPhone className={classes.clockPhoneIcon} />
                      <Text className={classes.text}>
                        Phone: {markerInfo.phone}
                      </Text>
                    </div>
                    <div className={classes.line}>
                      <IconClock className={classes.clockPhoneIcon} />
                      <Text className={classes.text}>
                        Business Hours: {markerInfo.hours}
                      </Text>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        <div className={classes.menu}>
          <Menu shadow="md" width={200} opened={menuOpened}>
            <Menu.Target>
              <div className={classes.filterButton}>
                <ActionIcon
                  size={45}
                  radius="lg"
                  variant="default"
                  sx={theme => ({
                    boxShadow: theme.shadows.sm,
                    border: menuOpened ? `2px solid #DA3831` : 'none',
                  })}
                  onClick={toggleMenu}>
                  <IconAdjustments color="#DA3831" width={25} height={25} />
                </ActionIcon>
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Filters</Menu.Label>
              <Menu.Item>
                <Switch
                  checked={showCorporate}
                  label="Corporate"
                  onChange={() => setShowCorporate(!showCorporate)}
                />
              </Menu.Item>
              <Menu.Item>
                <Switch
                  checked={showDistributors}
                  label="Distributors"
                  onChange={() => setShowDistributors(!showDistributors)}
                />
              </Menu.Item>
              <Menu.Item>
                <Switch
                  checked={showPartners}
                  label="Partners"
                  onChange={() => setShowPartners(!showPartners)}
                />
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      </GoogleMap>
    </LoadScript>
  );
}

export default DistributorLocations;

const useStyles = createStyles(theme => ({
  customInfoWindow: {
    backgroundColor: '#fafafa',
    padding: '5px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  },

  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },

  text: {
    marginTop: '10px',
  },

  line: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },

  clockPhoneIcon: {
    size: '24px',
    stroke: '1.5',
    marginRight: '10px',
    marginTop: '10px',
  },

  mapIcon: {
    size: '24px',
    stroke: '1.5',
    marginRight: '10px',
    marginTop: '20px',
  },

  globeIcon: {
    marginRight: '10px',
  },

  menu: {
    position: 'relative',
  },
  filterButton: {
    position: 'absolute',
    top: '60px',
    right: '7px',
  },
}));
